@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

:root{
	--bs-primary: #67C7DF;
	--bs-primary-light: #CCE3EA;
	--bs-primary-rgb: 109,199,223;
	--bs-primary-light-rgb: 204, 227, 234;
	--bs-success: #6A9F0D;
	--bs-success-rgb: 106,159,13;
	--bs-danger: #DB392C;
	--bs-danger-light: #FF7952;
	--bs-danger-rgb: 219,57,44;
	--bs-danger-light-rgb: 255, 121, 82;
	--bs-warning: #F5AF02;
	--bs-warning-rgb: 245,175,2;
	--bs-info: #2D527C;
	--bs-info-rgb: 45,82,124;
}

body {
	background-color: transparent !important; 
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 14px !important;
}

#main {
	padding: 1rem;
}
.burger-btn.click{
	left: 270px;
}
.burger-btn.click i::before{
	content : '\F659';
}
.burger-btn{
	position: absolute;
	top:5;
    left: 0;
    transition: left 0.4s ease;
}
.form-signin {
	width: 100%;
	max-width: 600px;
	padding: 15px;
	margin: auto;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #BABABA;
	opacity: 0.6; /* Firefox */
	font-size:14px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #BABABA;
	font-size:14px;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: #BABABA;
	font-size:14px;
}

/* Sidebar */
.sidebar-wrapper .sidebar-header .logo img{
	height: 2.5rem;
}
.sidebar-wrapper .sidebar-header img {
	height: auto;
}
	
.sidebar-wrapper {
	background-color : #F2FAFC;
	width: 270px;
	border-right: 1px solid #CCE3EA;
	position: fixed;
	bottom: 0;
	/*height: 150vh;*/
	overflow-y: auto;
	top: 0;
	transition: left 0.5s ease-out;
	z-index: 10;
}
#sidebar.active .sidebar-wrapper {
	left: 0;
}
#sidebar:not(.active) .sidebar-wrapper {
	left: -270px;
}
#sidebar:not(.active) ~ #main {
	margin-left: 0;
}

.sidebar-wrapper .sidebar-header{
	padding : 1rem 0;
	font-size: 2rem;
	font-weight: 700;
}
.header-bg{
	background-color: #fff;
	border-top: 1px solid #CCE3EA;
	border-bottom: 1px solid #CCE3EA;
}
.avatar{
	box-sizing: border-box;
	border: 2px solid #60C4DE;
	border-radius: 50%;
	display: inline-flex;
	position: relative;
	text-align: center;
	vertical-align: middle;
}

.avatar.avatar-lg .avatar-content, .avatar.avatar-lg img{
	width: 48px;
}
.avatar img {
	border-radius: 50%;
}

.sidebar-wrapper .menu {
	padding: 0 1.5rem;
	font-weight: 600;
	margin-top: 2rem;
}
.sidebar-wrapper .menu .sidebar-item.active>.sidebar-link, .sidebar-wrapper .menu .sidebar-link:hover {
	background-color: #fff;
	border-left: 6px solid;
	padding: 0.4rem 1.0rem;
	font-weight: 600;

}
.sidebar-wrapper .menu .sidebar-link span {
	margin-left: 0.5rem;
}
.sidebar-wrapper .menu .sidebar-item.active>.sidebar-link span {
	color: #41B8D8;
}
.sidebar-wrapper .menu .sidebar-link {
	border-radius: 0.4rem;
	color: #41B8D8;
	padding: 0.4rem 1.2rem;	
	align-items: center;
	display: block;
	display: flex;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.5s;
}
.sidebar-wrapper .menu .sidebar-item.active>.sidebar-link i, 
.sidebar-wrapper .menu .sidebar-item.active>.sidebar-link svg{
	fill: #41B8D8;
	color: #41B8D8;
}

.sidebar-wrapper .menu .sidebar-link:hover i, 
.sidebar-wrapper .menu .sidebar-link:hover svg{
	fill: #41B8D8;
	color: #41B8D8;
}
.sidebar-wrapper .menu .sidebar-link i, .sidebar-wrapper .menu .sidebar-link svg{
	color: #ACDFEC;
}
.sidebar-wrapper .menu .sidebar-item {
	list-style: none;
	margin-top: 0.5rem;
	position: relative;
}

.btn-check:focus+.btn, .btn:focus {
	box-shadow: none;
}
.sidebar-header > .dropdown-menu {
	box-shadow: none;
	background-clip: padding-box;
	background-color: #fff;
	border: 0 solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
	color: #607080;
	display: none;
	font-size: 1rem;
	list-style: none;
	margin: 0;
	min-width: 15rem;
	padding: 0.5rem 0;
	position: absolute;
	text-align: left;
	z-index: 1000;
}
.sidebar-item >.sidebar-link i[class*=" bot-"]::before{
	opacity:50%;
}
.sidebar-item >.sidebar-link:hover i[class*=" bot-"]::before,
.sidebar-item.active>.sidebar-link i[class*=" bot-"]::before {
	opacity:100%;
}

.sidebar-icon-size{
	height: 16px !important;
}

.dropdown-item {
	background-color: transparent;
	border: 0;
	clear: both;
	color: #212529;
	display: block;
	font-weight: 400;
	padding: 0.45rem 1.2rem;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	width: 100%;
}

.dropdown-item:focus, .dropdown-item:hover {
	color:#2D527C;
	background: #F2FAFC;
}

.dropdown-item.active, .dropdown-item:active {
	color: #2D527C;
	text-decoration: none;
	background-color: #F2FAFC;
}

.profile-div .dropdown-divider{
	border-top: 1px solid #f2fafc;
}
.profile-div .dropdown-menu.show{
	transform: translate3d(0px, 166px, 0px) !important;
	min-width: 270px;
}

.align-items-center {
	align-items: center !important;
}
.d-flex {
	display: flex !important;
}

.page-title{
	font-weight: 600;
	color:#323232;
	font-size: 24px;
}

.profile-div{
	width: 100%;
}
.profile-btn{
	width: inherit;
	color:#2D527C;
}
/* Customer Order */
.card-order-title{
	color: #696969;
}
.card-order-price{
	color: #323232;
	font-weight: 600;
}
/* Table Design */
.table-responsive{
	border-radius: 10px;
}

.tbl-item-details  > table{
	border-radius: 100px;
}

.tbl-item-details tbody{
	background: #fff;
}

.dataTable-table > thead, .table > thead {
	vertical-align: bottom;
}
.item-price{
	font-size:16px;
	font-weight: 500;
}
.item-title {
    font-weight: 500;
}
#main table{
	border-radius: 100px;
}
#main thead > tr {
	background: #F3F7F7;
	border-radius: 10px 10px 0px 0px;
	height: 52px;
}
#main tr > th {
	font-weight: 400;
	font-size: 14px;
	color: #696969;
	font-style: normal;
	vertical-align:middle;
}

#main tr > td {
	color: #000000 !important;
}

#main tr{
	border: 1px solid #e2eff3;
}

#main tbody > tr {
	height: 72px;
	vertical-align:middle;
}
#main .table > :not(:first-child) {
	border-top: 2px solid #dedede;
}

#main .table-striped > tbody > tr:nth-of-type(odd) > * {
	background: #F9FBFB;
}

#main .table-striped > tbody > tr:nth-of-type(even) > * {
	background: #f3f7f7;
}
.font-10 {
	font-size: 10px !important;
}
.font-12 {
	font-size: 12px !important;
}
.font-14 {
	font-size: 14px !important;
}
.font-16 {
	font-size: 16px !important;
}
.font-18 {
	font-size: 18px !important;
}
.font-20 {
	font-size: 20px !important;
}
.radius-10{
	border-radius: 10px;
}
.radius-15 {
    border-radius: 15px;
}
.radius-10-left{
	border-radius: 10px 0px 0px 10px;
}
.radius-10-right{
	border-radius: 0px 10px 10px 0px;
}
.weight-600{
	font-weight: 600;
}
.sub-promo{
	font-size:12px;
	color:#5b7fff
}
.item-detail{
	font-size:10px;
	font-weight: 600;
	color:#5b7fff
}
.item-variation{
	font-size:10px;
	font-weight: 600;
	color:#5b7fff
}
.sub-since-customer{
	font-size: 14px;
	color: #696969;
}
.action-icon-size{
	/* height: 20px !important;*/
	height: auto;
}
.paused > td {
	background-color: #FDEED9 !important;
}

/* Badges */
.badge {
	font-size: 14px;
	font-weight: 500;
}
.badge.bg-light-info{
	background-color:#CCECF4;
	color:#279CB9 !important;
}
.badge.bg-light-primary{
	background-color: #BCD0E7;
	color:#2D527C !important;
}
.badge.bg-promo{
	background-color: #DEE8FF;
	color:#4260DB !important;
}
.badge.bg-light-warning{
	background-color:#FBE3C0;
	color:#E88D0D !important;
}
.badge.bg-light-warning-white{
	background-color:#FFFFFF;
	color:#E88D0D !important;
}
.badge.bg-light-danger{
	background-color:#FFCFB1;
	color:#DB392C !important;
}
.badge.bg-light-success{
	background-color:#E3F89E;
	color:#6A9F0D !important;
}
.badge.bg-light-default{
	background-color:#DBDDE1;
	color:#525660 !important;
}
.badge.bg-light-processing{
	background-color:#FEEEA2;
	color:#D8A112 !important;
}
.badge.bg-light-purple{
	background-color:#F0DBE4;
	color:#BC5D87 !important;
}
.badge.bg-light-green{
	background-color:#CDEEE5;
	color:#178265 !important;
}
.badge.bg-light-disabled{
	background-color:#EDEDED;
	color:#BABABA !important;
}
.badge.bg-light-white{
	border: 1px solid #CCE3EA;
	background-color:#FFFFFF;
	color:#323232 !important;
}
.badge.bg-light-split{
	border: 1px solid #CCE3EA;
	background-color:#F2FAFC;
	color:#696969 !important;
}
/* Buttons */

.input-btn{
	background-color: #ffffff;
	border: 1px solid #CCE3EA;
	border-radius:10px;
	padding: 0.15rem 0.30rem;
	width:120px;
	height:35px;
}

.input-btn > .form-control, .input-btn > .form-control:focus{
	border:0px !important;
	box-shadow: none;
}
.btn-group > input {
	border-radius: 0px !important;
}

.btn-group-xs .btn , .btn-xs, btn-group-xs input {
	padding: 0rem 0.2rem;
	font-size: 1.25rem;
}
.btn{
	border-radius: 10px;
}
.btn.btn-info{
	background-color: #2D527C;
	color:#FFFFFF;
	border-color: #2D527C;
}
.btn-info:active, .btn-info:focus , .btn-info:target{
	border-color: #BCD0E7;
	box-shadow: 0 0 0 .25rem #BCD0E7;
}
.btn.btn-primary{
	background-color: #67C7DF;
	color:#FFFFFF;
	border-color: #67C7DF;
}
.btn-primary:active, .btn-primary:focus , .btn-primary:target{
	border-color: #CCE3EA;
	box-shadow: 0 0 0 .25rem #CCE3EA;
}

.btn.btn-info-light{
	background-color: #E2EFF3 !important;
	color:#2D527C !important;
	border-color: #E2EFF3 !important;
}
.btn-white{
	color:#000000;
	background-color: #ffffff;
}
.btn.btn-success{
	color:#FFFFFF;
	background-color: #6A9F0D;
	border-color: #6A9F0D;
}

.btn.btn-danger{
	background-color: #FF7952;
	color:#FFFFFF;
	border-color: #FF7952;
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus{
	box-shadow: 0 0 0 .25rem #CCE3EA;

}
.btn-check:active+.btn-outline-secondary, .btn-check:checked+.btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
	color:#2D527C;
	background: #F2FAFC;
	border-color: #CCE3EA;
}

.btn-check:active+.btn-outline-secondary:focus, .btn-check:checked+.btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus{
	box-shadow: 0 0 0 .10rem #CCE3EA;
}

.input-group .btn{
	color:#2D527C;
	background: #F2FAFC;
	border: 1px solid #CCE3EA;
	border-radius: 8px;
}
.btn-group > .btn{
	border-radius: 7px;
}
.btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--bs-primary-light-rgb),.5);
}
.btn-outline-primary, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary.dropdown-toggle.show{
	color : var(--bs-primary);
	background-color: white;
	font-weight: 500;
	border:2px solid;
	border-color : var(--bs-primary);
}

.btn-outline-danger, .btn-outline-danger:hover, .btn-outline-danger:active, .btn-outline-danger.dropdown-toggle.show{
	color : var(--bs-danger-light);
	background-color: white;
	font-weight: 500;
	border:2px solid;
	border-color : var(--bs-danger-light);
}
/*
.dropdown-toggle::after{
	content: none !important;
}*/
.hideDefaultDrpArrow::after {
    content: none !important;
}

.btn-group > .btn-outline-primary, .btn-group >.btn-outline-primary:hover, .btn-group >.btn-outline-primary:active, .btn-group > .btn-outline-primary.dropdown-toggle.show{
	border:1.5px solid;
	border-color : var(--bs-primary-light);

}
.item-action.dropdown-menu{
	min-width: fit-content !important;
	border-radius: 8px;
	border-color: var(--bs-primary-light);
	padding: 0.35rem 0;
}
.item-action.dropdown-menu > .dropdown-divider{
	border-color: var(--bs-primary-light);
	margin: 0.35rem 0;

}
.item-action.dropdown-menu > li > .dropdown-item{
	padding: 0 0.8rem;
}



/* Form Controls */
.form-select {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.9467%205.45331H7.79341H4.05341C3.41341%205.45331%203.09341%206.22664%203.54674%206.67997L7.00008%2010.1333C7.55341%2010.6866%208.45341%2010.6866%209.00674%2010.1333L10.3201%208.81997L12.4601%206.67997C12.9067%206.22664%2012.5867%205.45331%2011.9467%205.45331Z%22%20fill%3D%22%232D527C%22%2F%3E%3C%2Fsvg%3E") !important;
	background-size: 20px;
}
.form-control , .form-select{
	appearance: none;
	background-clip: padding-box;
	color: #607080;
	display: block;
	font-size: 1rem;
	padding: 0.375rem 0.75rem;
	padding-left: 0.75rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	width: 100%;
}
.form-control, .form-select {
	border-radius: 10px;
	background-color:#F9FBFB;
	border: 1px solid #E2EFF3;
}
.form-control, .form-select-white {
	border-radius: 10px;
	background-color: #ffffff;
	border: 1px solid #E2EFF3;
}
.tab-content .form-control, .tab-content .form-select {
	border-radius: 10px;
	background-color:#FFFFFF;
	border: 1px solid #CCE3EA;
}
.form-check-input{
	zoom: 1.3;
	margin-right: 5px;
	margin-top: 0.1em !important;
}
.form-switch .form-check-input{
	margin-left: -1.5em;
}

.form-switch .form-check-input:focus{
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e")
}
.form-switch .form-check-input{
	background-position: right center;
	height: 18px;
}
.form-switch .form-check-input:checked{
	background-position: left center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e")
}
.img-circle{
	box-sizing: border-box;
    display: inline-flex;
    vertical-align: middle;
	height: 125px;
	width: 125px;
}
.img-circle img{
	border-radius: 50% !important;
	background: #EDEDED;
	border: 5px solid #FFFFFF;
	object-fit: contain;
	max-width: 100%;
	height: 100%;
}
.img-circle input[type=file]{
	opacity: 0;
}
.float-button{
	position: relative;
	width:40px;
	height:40px;
	left:-20%;
	top:55%;
	border:0px;
	border-radius:50% !important;
	background-color: #ffffff;
}
.float-button:focus{
	box-shadow: 0 0 0 .10rem #CCE3EA;
}
.form-control:focus{
	box-shadow: 0 0 0 .10rem #CCE3EA;
}
.f-color{
	color: #2D527C !important;
}

.form-label, .col-form-label {
	font-size: 14px;
	font-weight: 500;
}

.form-check-input:checked {
	background-color: #60C4DE;
	border-color: #60C4DE;
}
.form-check-input:focus{
	border-color: #CCE3EA;
	box-shadow: 0 0 0 0.10rem #CCE3EA;
}
label.form-check-label {
	color: #2D527C;
}
.form-group {
	margin-bottom: 0.7rem;
}
.form-control.is-invalid, .was-validated .form-control:invalid{
	border-color : #DB392C !important;
}

.searchPanel > tags {
    max-height: 42px;
    overflow: auto;
}
.item {
    position:relative;
    padding-top:20px;
    display:inline-block;
}
.image-badge{
	position: absolute;
    top:35px;
    text-align: center;
    border-radius: 0px 5px 5px 0px;
    color:white;
    padding:5px 8px;
	font-size:10px;
}

/* Icon Left */
.form-group[class*="has-icon-"].has-icon-left .form-control, .form-group[class*="has-icon-"].has-icon-left .form-select {
	padding-left: 2.5rem;
}

.form-group[class*="has-icon-"].has-icon-left .form-control-icon {
	left: 0;
	top:0.28rem;
}
.form-group[class*="has-icon-"] .form-control-icon , .form-group[class*="has-icon-"] .form-control-icon-right {
	padding: 0 0.6rem;
	position: absolute;
}
.form-group[class*="has-icon-"] .form-control-icon i, .form-group[class*="has-icon-"] .form-control-icon svg {
	color: #adb5bd;
	font-size: 1.2rem;
	width: 1.2rem;
}

.form-control ~ .form-control-icon {
	top: 0.28rem;
}

/* Icon Right Start */
.form-group[class*="has-icon-"].has-icon-right .form-control, .form-group[class*="has-icon-"].has-icon-right .form-select {
	padding-right: 2.5rem;
}

.form-group[class*="has-icon-"].has-icon-right .form-control-icon-right {
	right: 0;
	top:0.28rem;
}

.form-group[class*="has-icon-"] .form-control-icon-right i, .form-group[class*="has-icon-"] .form-control-icon-right svg {
	color: #adb5bd;
	font-size: 1.2rem;
	width: 1.2rem;
}
/* Icon Right End */

.payment-history{
	color: #5B7FFF;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
}
.label-info{
	color : #6B6B6B;
	font-weight: 400;
	font-size: 12px;
	padding: 3px;
}
.input-group-control{
	background-color: #F2FAFC !important;
	border: 1px solid #CCE3EA !important;
	text-align: center;
}
.input-group > .input-group-control {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.input-group-span{
	width: 80% !important;
}
.input-group-text{
	background-color: #fff;
	border: 1px solid #CCE3EA;
	border-right:none;
}
.bg-textbox {
	background-color:#F9FBFB !important;
}

.input-group .dropdown-menu{
	min-width: auto;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
		margin-left: 0 !important; 
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
}
/* Card */
.card{
	border-radius: 10px !important;
	border-top:24px solid #67C7DF;
	border-bottom:16px solid #67C7DF;
}
.card-header{
	background-color:#F2FAFC;
	height: 80px;	
	border-bottom:1px solid #CCE3EA;			
}
.card-header .page-title{
	font-size:24px;
	font-weight:600;
}
.card-body{
	background-color: #F9FBFB;
}
.card .form-control, .modal-body .form-control {
	border: 1px solid #CCE3EA;
	background-color: #FFFFFF;
}

.dashboard > .card-body{
	background-color: transparent !important;
}
.card.dashboard {
	border-radius: 10px !important;
	background-color: #F9FBFB;
	border: 1px solid rgba(0,0,0,.125) !important;
}
.revenue-amount{	
	font-weight: 600;
	font-size: 24px;
}
.card-description{
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #696969;
}

/* Nav & Tabs */
.nav-tabs{
	border: 1px solid #CCE3EA;
	border-radius: 10px 10px 0px 0px;
	width: 100%;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active{
	color: #323232;
	background-color: #F2FAFC; 
	border-color:#CCE3EA;
	border-top: 1px solid #F2FAFC;
	border-radius: 0px;
	font-weight: 600;
	
}
.tab-title{
	color:#696969;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 16px;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
	border-color:#CCE3EA;
	border-radius: 0px;
	color: #000000;
}
.nav-tabs li:first-child .nav-link.active{
	border-radius: 10px 0px 0px 0px;
	border-left: 1px solid #F2FAFC;
}
.nav-tabs li:last-child .nav-link.active{
	border-radius: 0px 10px 0px 0px;
	border-right: 1px solid #F2FAFC;
}
.nav-link{
	color: #696969;
	font-style: normal;
}

.tab-content{
	border: 1px solid #CCE3EA;
	border-top: 1px solid #F2FAFC;
	border-radius: 0 0 10px 10px;
	background-color: #F2FAFC;
}

/* Modal Dialog */
.modal-content{
	border-radius : 10px !important;
	border-bottom: 16px solid #67C7DF;
	border-top: 24px solid #67C7DF;
	background-color: #F9FBFB;
	
}
.modal-body{
	margin: 0px !important;
	padding: 0px !important;
}
hr{
	color : #67C7DF;
}
.modal-form{
	margin: 20px;
}

.modal-header i {
	color: #67C7DF;
}
.modal-divider{
	border: 1px solid #67C7DF;
}
.modal-header{
	height:80px;
	padding : 20px 40px;
	background-color: #F2FAFC;
}
.modal-form .form-check-input{
	/* margin-top: 0.25em !important;*/
	margin-top: 0.05em !important;
}
.modal-footer{
	justify-content: flex-start;
	border-top: none;
	padding-top: 0px;
}
@media (min-width: 576px){
	.modal-md{
		max-width: 640px !important;
	}
}

@media (min-width: 380px) and (max-width:576px){
	.input-group-span{
		width: 70% !important;
	}
}
/* BotPricer Icons */
.bot-calendar::before {
	content : url(./icons/calendar.svg);
}
.bot-stripe::before {
	content : url(./icons/stripe.svg);
}
.bot-people::before {
	content : url(./icons/people.svg);
}
.bot-dashboard::before {
	content : url(./icons/dashboard.svg);
}
.bot-subscription::before {
	content : url(./icons/subscription.svg);
}
.bot-settings::before{
	content : url(./icons/settings.svg);
}
.bot-daily-sales::before{
	content: url(./icons/daily_sales.svg);
}
.bot-tracking-item::before{
	content: url(./icons/tracking_item.svg);
}
.bot-competitor::before{
	content: url(./icons/competitor.svg);
}
.bot-competitors::before{
	content: url(./icons/competitors.svg);
}
.bot-strategies::before{
	content: url(./icons/strategies.svg);
}
.bot-adaptive-profiles::before{
	content: url(./icons/adaptive_profiles.svg);
}
.bot-product-groups::before{
	content: url(./icons/product_groups.svg);
}
.bot-orders::before{
	content: url(./icons/orders.svg);
}
.bot-add-listing::before{
	content: url(./icons/add_listing.svg);
}
.bot-preference::before {
	content : url(./icons/master_settings.svg);
}
.bot-edit::before {	
	content: url(./icons/edit.svg);
}
.bot-edit-fill::before {
	content: url(./icons/edit_fill.svg);
	vertical-align: -0.5em;
}
.bot-arrow-down::before {
	content: url(./icons/arrow-down.svg);
}
.bot-logout::before {	
	content: url(./icons/logout.svg);
}
.bot-login::before {	
	content: url(./icons/login.svg);
}
.bot-login-front::before {	
	content: url(./icons/login_front.svg);
}
.bot-signup::before {	
	content: url(./icons/signup.svg);
}
.bot-send-message::before {	
	content: url(./icons/send_message.svg);
}
.bot-email::before {	
	content: url(./icons/email_front.svg);
}
.bot-phone::before {	
	content: url(./icons/phone.svg);
}
.bot-name::before {	
	content: url(./icons/name.svg);
}
.bot-resume::before {
	content: url(./icons/play-circle.svg);
}
.bot-hold::before {
	content: url(./icons/pause-circle.svg);
}
.bot-trash::before {
	content: url(./icons/trash.svg);
}
.bot-block-user::before {	
	content: url(./icons/profile-circle.svg);
}
.bot-link::before {	
	content: url(./icons/link.svg);
}
.bot-page-previous::before {	
	content: url(./icons/arrow_previous.svg);
}
.bot-page-next::before {	
	content: url(./icons/arrow_next.svg);
}
.bot-refund::before {	
	content: url(./icons/refund.svg);
}
.bot-search-normal::before {	
	content: url(./icons/search-normal.svg);
}
.bot-filter::before {	
	content: url(./icons/filter.svg);
}
.bot-chart::before {	
	content: url(./icons/chart.svg);
}
.bot-global::before {	
	content: url(./icons/global.svg);
}
.bot-dollar-circle::before {	
	content: url(./icons/dollar-circle.svg);
}
.bot-reprice-status::before {	
	content: url(./icons/reprice_status.svg);
}
.bot-position::before {	
	content: url(./icons/position.svg);
}
.bot-sold-30-days::before {	
	content: url(./icons/sold_30_days.svg);
}
.bot-source::before {	
	content: url(./icons/source.svg);
}
.bot-source-type::before {	
	content: url(./icons/source_type.svg);
}
.bot-reprice-type::before {	
	content: url(./icons/reprice_type.svg);
}
.bot-strategy::before {	
	content: url(./icons/strategy.svg);
}
.bot-ebay-site::before {	
	content: url(./icons/ebay_site.svg);
}
.bot-duplicate-sku::before {	
	content: url(./icons/duplicate_sku.svg);
}
.bot-problem::before {	
	content: url(./icons/problem.svg);
}
.bot-tag::before {	
	content: url(./icons/tag.svg);
}
.bot-item-with-variation::before {	
	content: url(./icons/item_with_variation.svg);
}
.bot-sort::before {	
	content: url(./icons/sort.svg);
}
.bot-order-status::before {	
	content: url(./icons/order_status.svg);
}
.bot-shipped-status::before {	
	content: url(./icons/shipped_status.svg);
}

[class^="bot-"].text-warning::before, [class*=" bot-"].text-warning::before{
	filter : invert(68%) sepia(74%) saturate(2794%) hue-rotate(356deg) brightness(93%) contrast(95%);
}
[class^="bot-"].text-warning-light::before, [class*=" bot-"].text-warning-light::before{
	filter : invert(59%) sepia(62%) saturate(1005%) hue-rotate(7deg) brightness(106%) contrast(98%);
}
[class^="bot-"].text-success::before, [class*=" bot-"].text-success::before {
	filter: invert(72%) sepia(43%) saturate(6928%) hue-rotate(47deg) brightness(92%) contrast(90%);
}
[class^="bot-"].text-danger::before, [class*=" bot-"].text-danger::before {
	filter: invert(18%) sepia(77%) saturate(4537%) hue-rotate(356deg) brightness(100%) contrast(72%);
}
[class^="bot-"].text-danger-light::before, [class*=" bot-"].text-danger-light::before {
	filter: invert(51%) sepia(89%) saturate(1107%) hue-rotate(328deg) brightness(108%) contrast(101%);;
}
[class^="bot-"].text-info::before, [class*=" bot-"].text-info::before {	
	filter: invert(30%) sepia(15%) saturate(1954%) hue-rotate(172deg) brightness(89%) contrast(89%);
}
[class^="bot-"].text-info1::before, [class*=" bot-"].text-info1::before {
	filter: invert(29%) sepia(21%) saturate(1502%) hue-rotate(171deg) brightness(91%) contrast(86%);
}
[class^="bot-"].text-primary::before, [class*=" bot-"].text-primary::before {
	filter: invert(82%) sepia(5%) saturate(4666%) hue-rotate(157deg) brightness(92%) contrast(90%);
}
[class^="bot-"].text-primary-light::before, [class*=" bot-"].text-primary-light::before {
	filter: invert(81%) sepia(49%) saturate(175%) hue-rotate(148deg) brightness(97%) contrast(92%);
}
[class^="bot-"].text-white::before, [class*=" bot-"].text-white::before {
	filter: invert(100%);
}
[class^="bot-"].text-secondary::before, [class*=" bot-"].text-secondary::before {
	filter: invert(42%) sepia(0%) saturate(1%) hue-rotate(81deg) brightness(96%) contrast(90%);
}


/* Bootstrap Datepicker */

.datepicker table tr td,
.datepicker table tr th {
	width: 35px;
	font-size: 14px !important;
	color: #6C757D;
}
.datepicker .datepicker-switch, .datepicker .prev, .datepicker .next{
	font-size: 14px !important;
	text-transform: uppercase;
}
.dow{
	text-transform: uppercase;

}
.datepicker table tr td.day:hover, .datepicker table tr td.focused {
	background: #F2FAFC;
}

.datepicker table tr td.old, .datepicker table tr td.new,	.datepicker table tr td.disabled {
	color: #6C757D;
	opacity: 40%;

}

.datepicker table tr td.active:active, .datepicker table tr td.active.highlighted:active, .datepicker table tr td.active.active, .datepicker table tr td.active.highlighted.active {
	color: #fff;
	border-color: #CCE3EA;
	background-color:#2D527C;	
}

/* Errors & Alerts */
.errors ul, .errors ol{
	margin-bottom: 0;
	padding-left : 1rem;
}

	
/* Bootstrap-notify */
.notifications {
	position: fixed;
	z-index: 9999;
}
	
.notifications.top-right {
	right: 10px;
	top: 25px;
}
	
.notifications.top-left {
	left: 10px;
	top: 25px;
}
	
.notifications.bottom-left {
	left: 10px;
	bottom: 25px;
}
	
.notifications.bottom-right {
	right: 10px;
	bottom: 25px;
}
.notifications > div {
	position: relative;
	margin: 5px 0px;
}

.bootstrap-notify-container.alert{
	padding: 15px 20px;
	border-radius: 10px;
	z-index: 1080 !important;
}

.alert-success {
	color: #6A9F0D;
	background-color: #E7FFBE;
	border-color: #E7FFBE;
}

.alert-danger {
	color: #DB392C;
	background-color: #FFE5E3;
	border-color: #FFE5E3;
}

.alert-warning {
	color: #F5AF02;

	background-color: #FFFBF1;
	border-color: #FFFBF1;
}

.alert-primary {
	color: #2D527C;
	background-color: #F2FAFC;
	border-color: #F2FAFC;
}

.alert-info {
	color: #2D527C;
	background-color: #F2FAFC;
	border-color: #F2FAFC;
}



/* Slim Scrollbar */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255, 255, 255, 0.3);
}

/* Datatables */
div.dataTables_wrapper div.dataTables_paginate ul.pagination{
	justify-content: center !important;
}
.table.dataTable{
	border-radius: 10px;
    margin: 0px !important;
}

.table.dataTable tr:last-child td:first-child { 
	border-bottom-left-radius: 10px !important; 
}
.table.dataTable tr:last-child td:last-child { 
	border-bottom-right-radius: 10px !important; 
}

.page-item{
	display: none;
}
.page-item:first-child, .page-item:last-child, .page-item.active{
    display:block !important;
}

.page-item.active .page-link{
	background-color: transparent !important;
	border: 0px !important;
	color: #000000;
	margin: 0 30px;
}
.page-link{
	border:0px !important;
	padding: 0 !important;
}
.page-link:focus, .page-link:hover{
	background-color: transparent;
	box-shadow: none;
}
.dataTables_info{
	padding-top: 0.5rem !important;
	font-size: 14px;
}

.fab-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	user-select: none;
	position: absolute;
	bottom: 30px; right: 30px;
}
.fab-container:hover {
	height: 100%;
}
.sub-button:nth-child(2) {
	transform: translateY(-80px);
}
.sub-button:nth-child(3) {
	transform: translateY(-140px);
}
.sub-button:nth-child(4) {
	transform: translateY(-200px);
}
.sub-button:nth-child(5) {
	transform: translateY(-260px);
}
.sub-button:nth-child(6) {
	transform: translateY(-320px);
}
.fab {
	position: relative;
	height: 70px; width: 70px;
	background-color: rgb(75, 162, 255);
	border-radius: 50%;
	z-index: 2;
}
.fab ::before {
	content: " ";
	position: absolute;
	bottom: 0; right: 0;
	height: 35px; width: 35px;
	background-color: inherit;
	border-radius: 0 0 10px 0;
	z-index: -1;
	}


.material-icons {
	color: white;
	font-size: 48px;
}
.fab-content {
display: flex;
align-items: center; justify-content: center;
height: 100%; width: 100%;
border-radius: 50%;
}

@media (min-width: 992px) {
	#main .section {
		margin-left: 270px;
	}
}


/*Customer side*/
.ebay-profile-name{
	font-size: 16px;
	color: #2D527C;
}
.ebay-id-label{
	font-size: 12px;
	color: #323232;
}

.divider{
	list-style: none;
	font-weight: 400;
	font-size: 12px;

	text-transform: uppercase;
	color: #7FC5D7;
	margin-top: 1.5rem!important;
    margin-bottom: 1rem!important;

}

