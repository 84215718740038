@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

 body{
     font-family:'IBM Plex Sans',sans-serif;
     font-style:normal;
}
/* Buttons */
 .btn{
     border-radius:10px;
}
 .btn.btn-primary{
     background-color:#67c7df;
     border-color:#67c7df;
     color:#ffffff;
}
 .btn-primary:active,.btn-primary:active:focus, .btn-primary:focus , .btn-primary:target{
     border-color: #CCE3EA;
     box-shadow: 0 0 0 .25rem #CCE3EA;
}
 .v-middle{
     vertical-align: middle !important;
}
/* Checkbox */
 .form-check-input:checked {
     background-color: #60C4DE;
     border-color: #60C4DE;
}
 .form-check-input:focus{
     border-color: #CCE3EA;
     box-shadow: 0 0 0 0.10rem #CCE3EA;
}
 label.form-check-label {
     font-weight: 400;
     font-size: 14px;
     color: #2D527C;
}
/* Form Control */
 .form-control{
     border: 1px solid #CCE3EA;
     border-radius: 10px;
}
 .form-label, .col-form-label {
     font-size: 14px;
     font-weight: 500;
}
 .input-group > input {
     border-left: 0px;
}
 .input-group > span{
     border-radius: 10px 0 0 10px;
     padding-right: 5px;
}
 .input-group-text {
     background: white;
}
 .input-group-text > .bi {
     color: #BABABA;
}
/* BotPricer Icons */
 .bot-check::before{
     content: url(./icons/check.svg);
}
 .bot-back::before{
     content: url(./icons/back.svg);
}
 .bot-next::before{
     content: url(./icons/next.svg);
}
 .bot-email::before{
     content: url(./icons/email.svg);
}
 .bot-password::before{
     content: url(./icons/password.svg);
}
.bot-money::before{
    content: url(./icons/f_money.svg);
}
.bot-sales::before{
    content: url(./icons/f_sales.svg);
}
.bot-save-time::before{
    content: url(./icons/f_time.svg);
}
.bot-integration::before{
    content: url(./icons/f_integration.svg);
}
.bot-increase-sales::before{
    content: url(./icons/f_increase_sales.svg);
}
.bot-dropship::before{
    content: url(./icons/f_dropshipping.svg);
}
.bot-f-arrow::before{
    content: url(./icons/f_btn_arrow.svg);
}
.bot-twitter::before{
    content: url(./icons/s_twitter.svg);
}
.bot-linkedin::before{
    content: url(./icons/s_linkedin.svg);
}
/* Homepage */
.home-header{
	background-color:#f2fafc;
}

.title{
	font-weight:700;
	font-size:45px;
	line-height:58px;
	color:#323232;
}
.subtitle{
	font-weight:500;
	font-size: 24px;
	line-height: 35px;
	color:#707070;
	text-align: start;
}
.b-subtitle{
	border-left: 6px solid #2D527C !important;
}

.title2{
	font-weight:700;
	font-size:32px;
	line-height:42px;
	color:#323232;
}
.feature-bg-1{
    background: rgba(155, 155, 155, 0.05);
}
.feature-title{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #323232;
}
.feature-subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #696969;
}
.get-started-bg{
    background: #2D527C;
    color:#ffffff;
}
.title-get-started{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
/* Pricing */
 .pricing-header{
     background-color:#f2fafc;
     padding-top:100px;
     padding-bottom:100px;
}
 .pricing-title{
     font-weight:600;
     font-size:30px;
     line-height:39px;
     color:#323232;
}
 .pricing-subtitle{
     font-weight:500;
     font-size: 22px;
     line-height: 29px;
}
 .pricing-tab{
     margin-top:-50px;
}
 .pricing-offer {
     margin-top: -8px;
     align-content: end;
     text-align: end !important;
     margin-right: 15px;
     position: absolute;
     align-self: end;
}
 .offer-text{
     position: absolute;
     top:0px;
     color:#ffffff;
     font-weight: 600;
     font-size: 18px;
}
 .offer-price{
     font-weight: 600;
     font-size: 20px;
     color:#D0D0D0;
     text-decoration: line-through;
     text-decoration-thickness: 1px;
}
 .pricing-card{
     border-radius: 10px;
     box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
     border:none;
     margin-bottom:10px;
}
 .pricing-card .card-body{
     background-color:#FFFFFF;
}
 .plan-name{
     font-weight:600;
     font-size:22px;
     line-height:29px;
     color:#323232;
}
 .plan-subtitle{
     font-weight:400;
     font-size:16px;
     line-height:21px;
     color:#323232;
}
 .plan-price{
     font-weight:600;
     font-size:35px;
     line-height:45px;
     color:#323232;
}
 .price-span{
     font-weight:400;
     color:#323232;
     font-size:18px;
     line-height:23px;
}
 .pricing-plan-features {
     list-style: none;
     padding-left:25px;
}
 .btn-plan{
     border-radius: 5px !important;
}
 .pricing-plan-features > li{
     font-weight: 400;
     font-size: 16px;
     margin-bottom:10px;
}
 .pricing-plan-features > li > i {
     margin-right: 10px;
     left:15px;
     position:absolute;
}
/* Signup */
 .form-signup{
     width:100%;
     max-width:600px;
     padding:15px;
     margin:auto;
}
 .page-title{
     font-size:24px;
     font-weight:600;
}
 .card-border{
     border-radius:10px !important;
     border-top:24px solid #67C7DF;
     border-bottom:16px solid #67C7DF;
}
 .card-title{
     background-color:#F2FAFC;
     height:80px;
     border-bottom:1px solid #CCE3EA;
}
 .card-bg-gray{
     background-color:#F9FBFB;
}
 .card-text-bold{
     font-weight: 500;
     font-size: 16px;
     color: #323232;
}
 .card-note{
     font-weight: 400;
     font-size: 12px;
     color: #323232;
}
 .text-14{
     font-weight: 400;
     font-size: 14px;
     color: #323232;
}
 .card-link {
     color: #4260DB;
     text-decoration: none;
     font-size: 14px;
     font-weight: 500;
}
 .btn-next-icon {
     vertical-align: middle;
     font-size: 20px;
}